<template>
    <div v-if="charge" class="awaiting-pix">
        <section class="awaiting-pix__payment-made pb-4">
            <svg class="my-4" xmlns="http://www.w3.org/2000/svg" width="3.2rem" height="3.2rem" viewBox="0 0 24 24" fill="none">
                <path d="M20.9498 14.55L14.5598 20.94C13.1598 22.34 10.8598 22.34 9.44978 20.94L3.05977 14.55C1.65977 13.15 1.65977 10.85 3.05977 9.44001L9.44978 3.05C10.8498 1.65 13.1498 1.65 14.5598 3.05L20.9498 9.44001C22.3498 10.85 22.3498 13.15 20.9498 14.55Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.25 6.25L17.75 17.75" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.75 6.25L6.25 17.75" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h1 class="m-0">PIX  Criado: só finalizar e confirmar!</h1>
        </section>
        <section class="awaiting-pix__info">
            <div class="awaiting-pix__pix d-flex flex-column gap-2" v-for="(pixCharge, index) in charge?.payment.charges" :key="index">
                <button class="mb-2" @click="copyPixCode(pixCharge.pixPayload!)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    Copiar código PIX
                </button>
                <ul class="d-flex flex-column gap-2">
                    <li>
                        <div class="d-flex align-items-center gap-2">
                            <div class="icon-wrapper first-child">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                                    <path d="M17 6V16C17 20 16 21 12 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H12C16 1 17 2 17 6Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11 4.5H7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.99995 18.1C9.85599 18.1 10.55 17.406 10.55 16.55C10.55 15.694 9.85599 15 8.99995 15C8.14391 15 7.44995 15.694 7.44995 16.55C7.44995 17.406 8.14391 18.1 8.99995 18.1Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <p class="m-0"><b>Abra o App do Banco:</b> Entre no aplicativo do seu banco.</p>
                        </div>
                    </li>
                    <li>
                        <div class="d-flex align-items-center gap-2">
                            <div class="icon-wrapper">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <p class="m-0"><b>Escolha a Opção PIX:</b> Selecione “PIX” no menu principal.</p>
                        </div>
                    </li>
                    <li>
                        <div class="d-flex align-items-center gap-2">
                            <div class="icon-wrapper">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M2 9V6.5C2 4.01 4.01 2 6.5 2H9" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15 2H17.5C19.99 2 22 4.01 22 6.5V9" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22 16V17.5C22 19.99 19.99 22 17.5 22H16" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9 22H6.5C4.01 22 2 19.99 2 17.5V15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.5 7V9C10.5 10 10 10.5 9 10.5H7C6 10.5 5.5 10 5.5 9V7C5.5 6 6 5.5 7 5.5H9C10 5.5 10.5 6 10.5 7Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.5 7V9C18.5 10 18 10.5 17 10.5H15C14 10.5 13.5 10 13.5 9V7C13.5 6 14 5.5 15 5.5H17C18 5.5 18.5 6 18.5 7Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.5 15V17C10.5 18 10 18.5 9 18.5H7C6 18.5 5.5 18 5.5 17V15C5.5 14 6 13.5 7 13.5H9C10 13.5 10.5 14 10.5 15Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.5 15V17C18.5 18 18 18.5 17 18.5H15C14 18.5 13.5 18 13.5 17V15C13.5 14 14 13.5 15 13.5H17C18 13.5 18.5 14 18.5 15Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <p class="m-0"><b>Insira os Dados:</b> Informe a chave PIX ou escaneie o QR Code.</p>
                        </div>
                    </li>
                    <li>
                        <div class="d-flex align-items-center gap-2">
                            <div class="icon-wrapper">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M22 11.1V6.9C22 3.4 20.6 2 17.1 2H12.9C9.4 2 8 3.4 8 6.9V8H11.1C14.6 8 16 9.4 16 12.9V16H17.1C20.6 16 22 14.6 22 11.1Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16 17.1V12.9C16 9.4 14.6 8 11.1 8H6.9C3.4 8 2 9.4 2 12.9V17.1C2 20.6 3.4 22 6.9 22H11.1C14.6 22 16 20.6 16 17.1Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.08008 15L8.03008 16.95L11.9201 13.05" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <p><b>Confirme e Finalize:</b> Revise e autorize o pagamento.</p>
                        </div>
                    </li>
                </ul>  
                <div class="d-flex align-items-center justify-content-center" style="gap: 2rem">
                    <img src="@/assets/pix.svg" class="my-2 mx-0" alt="">
                    <div style="width: 1px; background: #ccc; height: 38px"></div>
                    <div class="d-flex align-items-center flex-column">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p>Ambiente seguro</p>
                    </div>
                </div>
                <div class="d-flex flex-column gap-2 pb-4">
                    <img width="250" :src="`data:image/png;base64, ${pixCharge.pixQrCode}`" />
                    <button @click="consultPix">
                        Confirmar pagamento
                    </button>
                </div>              
            </div>
        </section>
    </div>
    <BackdropLoader v-else :zIndex="10" />
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue'
import { thankYouPages, charge } from '@/global/states/ChargeState'
import { toast } from 'vue3-toastify'
import router from '@/router/index'
import axiosClient from '@/api/axios';
import BackdropLoader from '@/components/loader/BackdropLoader.vue'

let pixInterval: number

function copyPixCode(pixPayload: string) {
    navigator.clipboard.writeText(pixPayload)
    .then(() => {
        toast.success('Código PIX copiado com sucesso!')
    })
    .catch(err => {
        toast.success('Erro ao copiar o código')
    });
}

function consultPix() {
    if (charge.value?.orderUUID) {
        axiosClient.get('/pix/consult/' + charge.value?.orderUUID)
            .then((res) => {
                if (res.data.result.status === 'success') {
                    const checkoutDetails = localStorage.getItem('checkoutDetails') ? JSON.parse(localStorage.getItem('checkoutDetails')!) : undefined

                    if (checkoutDetails?.thankYou?.pagePix) {
                        window.location.href = checkoutDetails?.thankYou?.pagePix
                    } else {
                        router.push({ name: 'thank-you' })
                    }
                }
            })
            .catch(() => router.push({ name: 'error' }))
    } else {
        router.push({ name: 'error' })
    }
}

onMounted(() => {
    consultPix()
    pixInterval = setInterval(consultPix, 5000)
})

onBeforeUnmount(() => {
    clearInterval(pixInterval)
})
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

#app {
    height: 100vh
}

.awaiting-pix {
    background: #198fe3;
    margin: auto;
    display: flex;
    flex-direction: column;
    font-family: Inter, 'sans-serif';
    gap: 1rem;
    height: 100%;
    h2 {
        font-size: 1rem;
        font-weight: 400;
    }
    &__payment-made {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        h1{
            color: #fff
        }
    }
    &__customer {
        background: #3a3b3b;
    }
    &__pix {
        img {
            margin: auto;
        }
        button {
            padding: 1rem;
            border: none;
            background: #8170fb;
            color: #fff;
            border-radius: .5rem
        }
        ul {
            list-style-type: none;
            .icon-wrapper {
                background: #3ccf80;
                border-radius: 50%;
                padding: .8rem .8rem;
                &.first-child {
                    padding: .8rem 1rem;
                }
            }
        }
    }
    &__info {
        background: #f9fbfa;
        width: 100%; 
        padding: 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        height: 100%;
        padding-top: 2rem;
        h2 {
            color: #3a3b3b
        }
    }
    ul {
        padding-left: 0rem
    }
}

@media screen and (max-width: 550px) {
    .awaiting-pix {
        &__payment-made {
            justify-content: center;
            align-items: center;
            padding: 2rem 0;
            svg {
                display: none;
            }
            h1{
                color: #fff;
                font-size: 1.2rem;
                display: inline-flex !important;
                width: unset !important
            }
        }
        li {
            font-size: .9rem;
        }
    }
}
</style>