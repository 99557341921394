import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "thank-you" }
const _hoisted_2 = { class: "thank-you__info" }
const _hoisted_3 = {
  style: {"display":"grid","grid-template-columns":"1fr 1fr"},
  class: "gap-4"
}
const _hoisted_4 = { class: "d-flex flex-column gap-2" }
const _hoisted_5 = { class: "thank-you__info__box border rounded-4" }
const _hoisted_6 = { class: "d-flex flex-column gap-2" }
const _hoisted_7 = { class: "thank-you__info__box border rounded-4" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex flex-column gap-2"
}
const _hoisted_9 = { class: "thank-you__info__box border rounded-4" }
const _hoisted_10 = { class: "d-flex flex-column gap-2" }
const _hoisted_11 = { class: "thank-you__info__box border rounded-4" }

import { charge } from '@/global/states/ChargeState'
import useFormat from '@/utils/format/useFormat';


export default /*@__PURE__*/_defineComponent({
  __name: 'ThankYou',
  setup(__props) {

const { formatDocument, formatPhoneNumber } = useFormat()
const checkoutDetails = localStorage.getItem('checkoutDetails') ? JSON.parse(localStorage.getItem('checkoutDetails')!) : undefined
const address: Partial<{neighborhood: string, district: string, number: number, street: string, complement: string, state: string, city: string, ibge: string}> = localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address')!) : undefined

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createStaticVNode("<section class=\"thank-you__payment-made pb-4\"><svg class=\"my-4\" xmlns=\"http://www.w3.org/2000/svg\" width=\"120\" height=\"120\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M7.75 11.9999L10.58 14.8299L16.25 9.16992\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg><h1 class=\"text-\">Pagamento efetuado!</h1></section>", 1)),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-flex align-items-center gap-2\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M12 8V13\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M11.9946 16H12.0036\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg><p class=\"m-0\">Informações</p></div>", 1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", null, "Número do pedido: " + _toDisplayString(_unref(charge).orderUUID), 1),
            _createElementVNode("h2", null, "Produto: " + _toDisplayString(_unref(charge).products.map((p) => p.title).join(', ')), 1),
            _createElementVNode("h2", null, "Data de compra: " + _toDisplayString(new Date((_unref(charge)).createdAt).toLocaleDateString()), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"d-flex align-items-center gap-2\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg><p class=\"m-0\">Cliente</p></div>", 1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h2", null, "Nome: " + _toDisplayString(_unref(charge).customer.name), 1),
            _createElementVNode("h2", null, "Documento: " + _toDisplayString(_unref(formatDocument)(String(_unref(charge).customer.document.number))), 1),
            _createElementVNode("h2", null, "Email: " + _toDisplayString(_unref(charge).customer.email), 1)
          ])
        ]),
        (_unref(address))
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"d-flex align-items-center gap-2\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M15.0098 2V12C15.0098 13.1 14.1098 14 13.0098 14H2.00977V6C2.00977 3.79 3.79977 2 6.00977 2H15.0098Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M22.0098 14V17C22.0098 18.66 20.6698 20 19.0098 20H18.0098C18.0098 18.9 17.1098 18 16.0098 18C14.9098 18 14.0098 18.9 14.0098 20H10.0098C10.0098 18.9 9.10977 18 8.00977 18C6.90977 18 6.00977 18.9 6.00977 20H5.00977C3.34977 20 2.00977 18.66 2.00977 17V14H13.0098C14.1098 14 15.0098 13.1 15.0098 12V5H16.8498C17.5698 5 18.2298 5.39001 18.5898 6.01001L20.2998 9H19.0098C18.4598 9 18.0098 9.45 18.0098 10V13C18.0098 13.55 18.4598 14 19.0098 14H22.0098Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M8.00977 22C9.11434 22 10.0098 21.1046 10.0098 20C10.0098 18.8954 9.11434 18 8.00977 18C6.9052 18 6.00977 18.8954 6.00977 20C6.00977 21.1046 6.9052 22 8.00977 22Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M16.0098 22C17.1143 22 18.0098 21.1046 18.0098 20C18.0098 18.8954 17.1143 18 16.0098 18C14.9052 18 14.0098 18.8954 14.0098 20C14.0098 21.1046 14.9052 22 16.0098 22Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M22.0098 12V14H19.0098C18.4598 14 18.0098 13.55 18.0098 13V10C18.0098 9.45 18.4598 9 19.0098 9H20.2998L22.0098 12Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg><p class=\"m-0\">Informações de entrega</p></div>", 1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("h2", null, "Rua: " + _toDisplayString(_unref(address).street), 1),
                _createElementVNode("h2", null, "Número: " + _toDisplayString(_unref(address).number), 1),
                _createElementVNode("h2", null, "Complemento: " + _toDisplayString(_unref(address).complement), 1),
                _createElementVNode("h2", null, "Bairro: " + _toDisplayString(_unref(address).neighborhood), 1),
                _createElementVNode("h2", null, "Cidade: " + _toDisplayString(_unref(address).city), 1),
                _createElementVNode("h2", null, "Estado: " + _toDisplayString(_unref(address).state), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"d-flex align-items-center gap-2\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"25\" height=\"24\" viewBox=\"0 0 25 24\" fill=\"none\"><path d=\"M17.0156 18.43H13.0156L8.56561 21.39C7.90561 21.83 7.01562 21.36 7.01562 20.56V18.43C4.01562 18.43 2.01562 16.43 2.01562 13.43V7.42993C2.01562 4.42993 4.01562 2.42993 7.01562 2.42993H17.0156C20.0156 2.42993 22.0156 4.42993 22.0156 7.42993V13.43C22.0156 16.43 20.0156 18.43 17.0156 18.43Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M12.0156 11.36V11.15C12.0156 10.47 12.4357 10.11 12.8557 9.82001C13.2657 9.54001 13.6756 9.18002 13.6756 8.52002C13.6756 7.60002 12.9356 6.85999 12.0156 6.85999C11.0956 6.85999 10.3557 7.60002 10.3557 8.52002\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M12.0111 13.75H12.0201\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg><p class=\"m-0\">Suporte</p></div>", 1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h2", null, "Email: " + _toDisplayString(_unref(checkoutDetails).support.email), 1),
            _createElementVNode("h2", null, "Telefone: " + _toDisplayString(_unref(formatPhoneNumber)(_unref(checkoutDetails).support.phone)), 1),
            _createElementVNode("h2", null, "Website: " + _toDisplayString(_unref(checkoutDetails).support.website), 1)
          ])
        ])
      ])
    ])
  ]))
}
}

})