import { ref } from "vue";

interface Payment {
  hasErrors: boolean;
  charges: Charge[];
}

interface Charge {
  uuid: string;
  amount: number;
  type: string;
  hasErrors: boolean;
  pixPayload?: string;
  pixQrCode?: string;
  billetUrl?: string;
  billetBarCode?: string;
  expireAt: string;
}

interface Phone {
  phoneNumber: string;
  masked: string;
  numbersOnly: string;
}

interface Document {
  number: string;
  type: string;
}

interface Customer {
  email: string;
  name: string;
  phone: Phone;
  document: Document;
}

interface Data {
  browser: string;
  version: string;
  platform: string;
  source: string;
  urlRefer: string;
  ip: string;
  cookie: string;
  callbackURL: string;
}

interface Commission {
  userId: number;
  type: string;
  amount: number;
}

interface Product {
  title: string;
  price: number;
  quantity: number;
  amount: number;
  discount: number;
}

interface Charge {
  payment: Payment;
  customer: Customer;
  data: Data;
  commissions: Commission[];
  products: Product[];
  createdAt: string;
  orderUUID: string;
}

export const charge = ref<Charge>(localStorage.getItem('charge') ? JSON.parse(localStorage.getItem('charge')!) : undefined)
export const thankYouPages = ref<{ pageCreditCard: string, pagePix: string, pageBillet: string }>()